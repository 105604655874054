import React from "react"
import PropTypes from "prop-types"

import "./Footer.less"
import MenuLinks from "../../common/MenuLinks/MenuLinks"
import Logo from "../../static_templates/Logo/Logo"
import Contacts from "../../static_templates/Contacts/Contacts"
import Socials from "../../static_templates/Socials/Socials"
import BottomLogo from "../../static_templates/Logo/BottomLogo"
import MenuLinks_Style2 from "../../common/MenuLinks/MenuLinks_Style2"

const Footer = () => {
  const menuOne = [{
    id: 1,
    text: "Studio",
    link: "/about/"
  }, {
    id: 2,
    text: "Projects",
    link: "/interior-design-portfolio/"
  }, {
    id: 3,
    text: "Services",
    link: "/interior-design/"
  }]
  const menuTwo = [{
    id: 4,
    text: "Journal",
    link: "/home-decor-blog/"
  }, {
    id: 5,
    text: "Contact",
    link: "/contact/"
  }, {
    id: 6,
    text: "Terms & Conditions",
    link: "/terms/"
  }]

  const menuTree = [{
    id: 7,
    text: "Location",
    link: "",
    subitems: [{
      id: 1,
      text: "INTERIOR DESIGNERS IN CHELSEA",
      link: "/interior-designers-chelsea/"
    }, {
      id: 2,
      text: "INTERIOR DESIGNERS IN HAMPSTEAD",
      link: "/interior-designers-hampstead/"
    }, {
      id: 3,
      text: "INTERIOR DESIGNERS IN KNIGHTSBRIDGE",
      link: "/interior-designers-knightsbridge/"
    }, {
      id: 4,
      text: "INTERIOR DESIGNERS IN MAYFAIR",
      link: "/interior-designers-mayfair/"
    }, {
      id: 5,
      text: "INTERIOR DESIGNERS IN ST JOHNS WOODS",
      link: "/interior-designers-st-johns-woods/"
    }, {
      id: 6,
      text: "INTERIOR DESIGNERS IN NOTTING HILL",
      link: "/interior-designers-notting-hill/"
    }, {
      id: 7,
      text: "INTERIOR DESIGNERS IN BATTERSEA",
      link: "/interior-designers-battersea/"
    }]  
  },
  {
    id: 8,
    text: "Tags",
    link: "",
    subitems: [{
      id: 1,
      text: "LUXURY INTERIOR DESIGNERS",
      link: "/luxury-interior-designers-london/"
    }]
  }
]


  return (
    <footer className="at-footer flex pt-6 px-5 pb-2 md:pt-12 md:px-5 md:pb-24 m-0 mt-4 xl:mt-7 flex-col md:flex-initial" style="border-top: 1px solid #aaaaaa;">
      <div className="relative w-full max-w-[1200px] my-0 mx-auto flex items-center flex-wrap md:flex-initial">
        <BottomLogo />
        <div className="flex w-full md:w-auto ml-[15%] mr-[0%] md:ml-10 lg:ml-20">
          <div className="w-1/2 md:w-auto">
            <MenuLinks items={menuOne} type="column" className="md:block" />
          </div>
          <div className='w-1/2 md:w-auto md:ml-4 lg:ml-8'>
            <MenuLinks items={menuTwo} type="column" className="md:block" />
          </div>
          <div className='w-1/2 md:w-auto md:ml-4 lg:ml-8'>
            <MenuLinks_Style2 items={menuTree} type="column" className="md:block" />
          </div>
        </div>
        <Contacts />
        <div className="w-full my-0 mx-auto text-center md:w-auto md:absolute md:left-[243px] md:top-[130px] lg:left-[290px] lg:top-[130px] md:text-left">
        <Socials />
      </div>

      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
