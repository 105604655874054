import React, {useContext} from "react"
import PropTypes from "prop-types"

import { MenuContext } from "../../../context"
import "./MenuSwitcher.less"

const MenuSwitcher = ({ blok }) => {
  const {setMenuState} = useContext(MenuContext);
  const openMenu = function(){
    setMenuState('open');
  }

  return (
    <div className='at-burger' onClick={openMenu} onKeyDown={openMenu} role="button" tabIndex="0" aria-label="Open Menu">
      <div className='at-burger__switcher'>
        <div className="top"></div>
        <div className="middle"></div>
        <div className="bottom"></div>
      </div>
    </div>
  )
}

MenuSwitcher.propTypes = {
  items: PropTypes.array,
}

MenuSwitcher.defaultProps = {
  siteTitle: {},
}

export default MenuSwitcher
