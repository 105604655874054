import React, {useContext} from "react"

import { CurrentPageContext } from "../../../context"
import { Link } from "gatsby";
import { ChevronRightIcon } from '@heroicons/react/24/outline'


const Breadcrumbs = ({ blok }) => {

    const {currentPage, setCurrentPage} = useContext(CurrentPageContext);

    return (
      <div className='flex flex-wrap my-0 px-0 pt-6 pb-2'>
        <div className='text-sm no-underline' title='Home'>
          <Link to={"/"}>
            Home
          </Link>
        </div>
        {currentPage && currentPage.map((item, index) => {
          return (
            <>
              <div className="py-0.5 px-3"><ChevronRightIcon className="w-4 h-4 text-black"/></div>
              <div className='text-sm no-underline' title={item.name}>
                {item.slug ? (
                  <Link to={item.slug}>
                    {item.name}
                  </Link>
                ) : item.name}
              </div>
            </>
          )
        })}

      </div>
    )
}
export default Breadcrumbs
