import { Link } from "gatsby";
import React from "react";
import cn from "classnames";

const MenuLinks = ({ items, type = 'row', className = "hidden ml-auto" }) => {
    return (
        <ul className={cn("items-center list-none m-0", className, type == 'row' ? 'md:flex' : 'md:block')}>
            {items.map((item) => {
                return (
                    <li className={cn("group md:mr-7 lg:mr-14 leading-3 last:mr-0 relative", item.subitems ? "group-with-subitems" : "", type == "row" ? "mb-0" : "mb-8 last:mb-0")} key={item.id}>
                        <Link className="text-gray-800 group-[.transparent]:text-white group-[.transparent]:group-hover:text-gray-800 group-[.opaque]:text-gray-800 group-hover:text-gray-800 font-sans text-xxs not-italic font-normal tracking-widest uppercase" to={item.link}>{item.text}</Link>
                        {item.subitems && <>
                            <ul className={cn("hidden text-center w-52 absolute m-0 -left-20 px-2 pt-10 py-5 bg-white", "group-[.group-with-subitems]:group-hover:block hover:block")}>
                                {item.subitems.map((subitem) => {
                                    return <li className="mb-4 last:mb-0" key={subitem.id}>
                                        <Link className="text-center font-sans text-xxs not-italic font-normal tracking-widest uppercase" to={subitem.link}>{subitem.text}</Link>
                                    </li>
                                })}
                            </ul>
                        </>}
                    </li>
                )
            })}
        </ul>
    )
}

export default MenuLinks