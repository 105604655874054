import * as React from "react"
import { Link } from "gatsby"

import LogoBlack from './../../../images/logo-black.svg';
import LogoWhite from "./../../../images/logo-white.svg";

const Logo = () => (
  <div className='at-logo'>
    <Link to='/' aria-label="Black&Milk">
      <img alt="BlackAndMilk" className="at-logo__black" src={LogoBlack} />
      <img alt="BlackAndMilk" className="at-logo__white" src={LogoWhite} />
    </Link>
  </div>
)

export default Logo
