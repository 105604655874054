import React from "react"

import Logo from "../../static_templates/Logo/Logo"
import MenuLinks from "../../common/MenuLinks/MenuLinks"
import MenuSwitcher from "../../common/MenuSwitcher/MenuSwitcher"
import "./Header.less"
import { headerMenu } from "../../../data/menu"

const Header = ({ siteTitle, classCssName }) => {
  return (
    <header className={classCssName}>
      <div className="at-header__wrapper">
        <Logo />
        <MenuLinks items={headerMenu} />
        <MenuSwitcher />
      </div>
    </header>
  )
}

export default Header
