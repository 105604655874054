const menu = [{
    id: 1,
    text: "Studio",
    link: "/about/"
  }, 
  {
    id: 2,
    text: "Projects",
    link: "/interior-design-portfolio/"
  },
  {
    id: 3,
    text: "Services",
    link: "/interior-design/",
    subitems: [
      {
        id: 31,
        text: "Architecture",
        link: "/interior-design/london-architecture/",    
      },
      {
        id: 32,
        text: "Interior Architecture",
        link: "/interior-design/interior-architecture-london/",    
      },
      {
        id: 33,
        text: "Interior Design",
        link: "/interior-design/interior-design-london/",
      },
      {
        id: 34,
        text: "Project Management",
        link: "/interior-design/construction-project-management/",
      },
      {
        id: 35,
        text: "Procurement",
        link: "/interior-design/interior-design-procurement/",
      }
    ]
  },
  {
    id: 4,
    text: "Musings",
    link: "/musings/"
  },
  {
    id: 5,
    text: "Journal",
    link: "/home-decor-blog/"
  },
  {
    id: 6,
    text: "Contact",
    link: "/contact/"
  }]

const headerMenu = [...menu];
const sidebarMenu = [...menu];

sidebarMenu.unshift({
    id: 0,
    text: "Home",
    link: "/"
});

export { headerMenu, sidebarMenu };
  