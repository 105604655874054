import React, {useContext, useState} from "react"
import { Link } from "gatsby"

import { MenuContext } from "../../../context"
import Logo from "../../static_templates/Logo/Logo"
import "./Sidebar.less"
import { sidebarMenu } from "../../../data/menu"
import cn from 'classnames';
import { IoIosArrowDropdown } from "react-icons/io";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

const Sidebar = () => {

  const {menuState, setMenuState} = useContext(MenuContext);
  const [subMenuStates, setSubMenuStates] = useState({});

  const closeMenu = function(){
    setTimeout(() => {
        setMenuState('close');
    }, 100);
  }

  const toggleSubMenu = function(index) {
    const subMenuState = subMenuStates && subMenuStates[index] ? subMenuStates[index] : false;
    setSubMenuStates({
      ...subMenuStates,
      [index]: !subMenuState
    })
  }

  return (
    <div className={'at-sidebar ' + menuState}>
      <div className="at-sidebar__close" role="button" tabIndex="0" onKeyDown={closeMenu} onClick={closeMenu} aria-label="Sidebar Close">
        <div className="at-sidebar__close-first"></div> 
        <div className="at-sidebar__close-second"></div>
      </div>
      <div className="at-sidebar__logo">
        <Logo />
      </div>
      <ul className="at-sidebar__menu">
        {sidebarMenu.map((item, index) => { 
          if(item.subitems){
            return (
              <li className="at-sidebar__menu-li" key={index}>
                <span className="relative" role="button" tabIndex="0" aria-label="Sidebar Close">
                  <Link to={item.link} className="at-sidebar__menu-a" onKeyDown={closeMenu} onClick={closeMenu}>{item.text}</Link>
                  <span className="absolute -right-7" onClick={(e) => {e.preventDefault(); toggleSubMenu(index);}}>
                    {
                    subMenuStates && subMenuStates[index] 
                      ? <TiArrowSortedUp className="w-5 h-5 text-[#C0C0C0]" />
                      : <TiArrowSortedDown className="w-5 h-5 text-[#C0C0C0]" />
                    } 
                  </span>
                </span>
                <ul className={cn("m-0 mt-5 text-center font-sans text-xs not-italic font-normal tracking-widest uppercase", subMenuStates && subMenuStates[index] ? "" : "hidden" )}>
                  {item.subitems.map((subitem, index) => {
                    return (
                      <li className="at-sidebar__submenu-li" key={index}>
                        <div role="button" tabIndex="0" onKeyDown={closeMenu} onClick={closeMenu} aria-label="Sidebar Close"><Link to={subitem.link} className="at-sidebar__submenu-a">{subitem.text}</Link></div>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          } else {
            return (
              <li className="at-sidebar__menu-li" key={index}>
                <div role="button" tabIndex="0" onKeyDown={closeMenu} onClick={closeMenu} aria-label="Sidebar Close">
                  <Link to={item.link} className="at-sidebar__menu-a">{item.text}</Link>
                </div>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

export default Sidebar
