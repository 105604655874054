import React from "react"
import { Link } from "gatsby"

import "./Contacts.less"

// contacts block in footer and header
const Contacts = () => (
  <div className='at-contacts'>
      <div className='at-contacts__one address'>
        <a target="_blank" to="https://maps.app.goo.gl/7otNK9rJfoSwjEb39">
          <div className='at-contacts__one-label'>Black and Milk LTD</div>
          <div className='at-contacts__one-label'>101-103 Heath Street</div>
          <div className='at-contacts__one-label'>London, NW3 6SS</div>
        </a>
      </div>
      <div className='at-contacts__one phone'>
        <span className='at-contacts__one-label'>T -</span>
        <a href='tel:+4402034174884' className='at-contacts__one-value desktop'>+44 (0)20 34174884</a>
        <a href='tel:+4402034174884' className='at-contacts__one-value mobile'><span className="material-icons">call</span></a>
      </div>
      <div className='at-contacts__one email'>
        <span className='at-contacts__one-label'>E -</span>
        <a href='mailto:mail@blackandmilk.co.uk' className='at-contacts__one-value desktop'>mail@blackandmilk.co.uk</a>
        <a href='mailto:mail@blackandmilk.co.uk' className='at-contacts__one-value mobile'><span className="material-icons">email</span></a>
      </div>
  </div>
)

export default Contacts
