exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-decor-blog-js": () => import("./../../../src/pages/home-decor-blog.js" /* webpackChunkName: "component---src-pages-home-decor-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interior-design-blog-js": () => import("./../../../src/pages/interior-design-blog.js" /* webpackChunkName: "component---src-pages-interior-design-blog-js" */),
  "component---src-pages-interior-design-news-js": () => import("./../../../src/pages/interior-design-news.js" /* webpackChunkName: "component---src-pages-interior-design-news-js" */),
  "component---src-pages-interior-design-portfolio-js": () => import("./../../../src/pages/interior-design-portfolio.js" /* webpackChunkName: "component---src-pages-interior-design-portfolio-js" */),
  "component---src-pages-journal-media-js": () => import("./../../../src/pages/journal-media.js" /* webpackChunkName: "component---src-pages-journal-media-js" */),
  "component---src-pages-journal-press-js": () => import("./../../../src/pages/journal-press.js" /* webpackChunkName: "component---src-pages-journal-press-js" */),
  "component---src-templates-journalpost-js": () => import("./../../../src/templates/journalpost.js" /* webpackChunkName: "component---src-templates-journalpost-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-portfoliowork-js": () => import("./../../../src/templates/portfoliowork.js" /* webpackChunkName: "component---src-templates-portfoliowork-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

