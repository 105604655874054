import React from "react";
import "./Socials.less"

const Socials = () => {
    return (
        <div className="at-socials mt-8 mb-8">
            <a aria-label="Instagram" href="https://www.instagram.com/blackandmilk/" target="_blank" rel="noreferrer nofollow" className="at-socials__one instagram"></a>
            <a aria-label="Pinterest" href="https://www.pinterest.co.uk/Black_and_Milk/" target="_blank" rel="noreferrer nofollow" className="at-socials__one pinterest"></a>
        </div>
    )
}

export default Socials
