/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../../../styles/reset.less"
import "../../../styles/global.less"
import "./Layout.less"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Sidebar from "../Sidebar/Sidebar"
import { MenuContext, CurrentPageContext } from "../../../context"
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs"
import { TfiArrowCircleUp } from "react-icons/tfi";



const Layout = ({ children }) => {
  const [isHeaderWhite, setHeaderWhite] = useState('opaque')
  const [menuState, setMenuState] = useState('close')
  const [currentPage, setCurrentPage] = useState(null)
  const isBrowser = () => typeof window !== "undefined"
  const InitHeader = () => {
    let bannerShifted = document.getElementsByClassName('shifted-header');
    if (bannerShifted.length) {
      let bannerHeight = bannerShifted[0].clientHeight - 100;
      if (window.scrollY > bannerHeight) {
        setHeaderWhite('opaque')
      } else {
        setHeaderWhite('transparent')
      }
    } else {
      setHeaderWhite('disable-shift')
    }
  }

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    setInterval(() => {
      InitHeader();
    }, 500)
  }, []);


  if (isBrowser()) {
    window.addEventListener('scroll', event => {
      InitHeader();
    })
  }

  return (
    <>
      <CurrentPageContext.Provider value={{
        currentPage, setCurrentPage
      }}>
        <MenuContext.Provider value={{
          menuState, setMenuState
        }}>
          <div className={'at-wrapper ' + menuState}>
            <Header classCssName={'at-header group ' + isHeaderWhite} siteTitle={data.site.siteMetadata?.title || `Title`} />
            <div className='at-content'>
              {children}
            </div>
            <div role="button" tabIndex="0" className="at-top-button" onClick={handleScrollToTop} onKeyDown={handleScrollToTop} aria-label="Scroll to top">
              <div className="at-top-button__text">Back to Top</div>
              <div className="pointer"><TfiArrowCircleUp className="fill-[#c0c0c0] bg-white rounded-full w-7 h-7 md:w-9 md:h-9" /></div>
            </div>
            <Footer />

            <Sidebar />
          </div>
        </MenuContext.Provider>
      </CurrentPageContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
